import React from 'react'
import { TextBreak } from './TextBreak'
import { css } from '@emotion/core'
import { HighlightText } from './HighlightText'
import { SectionHeading } from './SectionHeading'
import { ContentText } from './ContentText'
import { TABLET_OR_MORE, DESKTOP_OR_MORE } from '~/constants/theme.styles'
import ArrowLink from '~/components/common/Links/ArrowLink'
import Container from '~/components/common/Container'
import { useModalActions } from '~/context/ModalContext'

const rowStyles = css`
  @media ${TABLET_OR_MORE} {
    display: flex;
  }
`
const headingColStyles = css`
  @media ${TABLET_OR_MORE} {
    width: 50%;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
`
const descriptionColStyles = css`
  margin-top: 40px;
  @media ${TABLET_OR_MORE} {
    margin-top: 0;
    width: 50%;
    flex-grow: 1;
  }
`

export const DescriptionSection = () => {
  const modalActions = useModalActions()
  return (
    <Container
      css={css`
        margin-top: 80px;
        text-align: center;
        @media ${TABLET_OR_MORE} {
          margin-top: 0;
          text-align: left;
        }
      `}
    >
      <div css={rowStyles}>
        <div css={headingColStyles}>
          <SectionHeading>
            We help you{' '}
            <TextBreak>
              <HighlightText color="blue">find the right job</HighlightText>
            </TextBreak>
          </SectionHeading>
        </div>
        <div css={descriptionColStyles}>
          <ContentText
            css={css`
              padding-top: 0px;
              @media ${TABLET_OR_MORE} {
                padding-top: 35px;
              }
            `}
          >
            We believe that you deserve a job search experience that is human and personal. We go
            beyond simple keyword searches.
          </ContentText>

          <ArrowLink
            bold
            underline="always"
            color="black"
            css={css`
              margin-top: 46px;
              margin-left: auto;
              margin-right: auto;
              font-size: 18px;
              display: inline-block;
              @media ${TABLET_OR_MORE} {
                font-size: 18px;
                line-height: 31px;
                margin-left: 0;
                margin-right: 0;
                margin-top: 30px;
              }
              @media ${DESKTOP_OR_MORE} {
                font-size: 22px;
                line-height: 34px;
              }
            `}
            onClick={() => {
              modalActions.setOpen('onboarding', true)
            }}
          >
            Ready to build your Joblist?
          </ArrowLink>
        </div>
      </div>
    </Container>
  )
}
