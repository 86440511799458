import React from 'react'
import { css } from '@emotion/core'
import { TextBreak } from './TextBreak'
import Button from '~/components/common/Button'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { TABLET_OR_MORE, DESKTOP_OR_MORE, DESKTOP_WIDE } from '~/constants/theme.styles'

import { HighlightText } from './HighlightText'
import Container from '~/components/common/Container'
import { useModalActions } from '~/context/ModalContext'

const wideWrapperStyles = css`
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const containerStyles = css`
  z-index: 20;
  position: relative;
`

const contentWrapperStyles = css`
  text-align: center;
  @media ${TABLET_OR_MORE} {
    position: absolute;
    text-align: left;
    margin-top: 45px;
  }
`

const headingStyles = css`
  font-size: 34px;
  margin-top: 30px;
  font-weight: 600;
  @media (min-width: 375px) {
    font-size: 38px;
  }
  @media ${TABLET_OR_MORE} {
    font-size: 60px;
    margin-top: 0;
  }
  @media ${DESKTOP_OR_MORE} {
    font-size: 70px;
  }
  @media ${DESKTOP_WIDE} {
    font-size: 80px;
  }
`
const subHeadingStyles = css`
  font-size: 16px;
  line-height: 1.45em;
  color: #222;
  max-width: 400px;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 375px) {
    font-size: 18px;
  }
  @media ${TABLET_OR_MORE} {
    font-size: 18px;
    line-height: 31px;
    margin-left: 0;
    margin-right: 0;
    max-width: 405px;
  }
  @media ${DESKTOP_OR_MORE} {
    font-size: 22px;
    line-height: 34px;
  }
`

const buttonStyles = css`
  min-width: 230px;
  margin-top: 45px;
  height: 60px;
  @media ${TABLET_OR_MORE} {
    height: 68px;
    font-size: 17px;
  }
  @media ${DESKTOP_OR_MORE} {
    min-width: 250px;
  }
`

const mobileIllustrationStyles = css`
  padding-top: 99%;
  background-image: url('${PUBLIC_ASSET_PREFIX}/static/images/home-hero-v3@2x.png');
  background-size: 204%;
  background-repeat: no-repeat;
  background-position: 60% -46px;
  margin-top: -54px;
  @media (min-width: 375px) {
    margin-top: -20vw;
  }
  @media ${TABLET_OR_MORE} {
    display: none;
  }
`

const desktopIllustrationStyles = css`
  display: none;
  @media ${TABLET_OR_MORE} {
    display: block;
    height: calc(500px + 12vw);
    background-image: url('${PUBLIC_ASSET_PREFIX}/static/images/home-hero-v3@2x.png');
    background-size: 87%;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
  @media ${DESKTOP_OR_MORE} {
    height: calc(494px + 15vw);
    background-size: 83%;
  }
  @media ${DESKTOP_WIDE} {
    height: calc(524px + 15vw);
    max-height: 760px;
  }
`

export const HeroSection = () => {
  const modalActions = useModalActions()
  return (
    <div css={wideWrapperStyles}>
      <Container css={containerStyles}>
        <div css={contentWrapperStyles}>
          <h1 css={headingStyles}>
            Every job search{' '}
            <TextBreak>
              is <HighlightText>different</HighlightText>
            </TextBreak>
          </h1>
          <p css={subHeadingStyles}>
            Answer a few short questions and we'll create a personalized set of job matches.
          </p>

          <Button
            raised
            arrow
            extraCss={buttonStyles}
            onClick={() => {
              modalActions.setOpen('onboarding', true)
            }}
          >
            Get Started
          </Button>
        </div>
      </Container>
      <div css={desktopIllustrationStyles} />
      <div css={mobileIllustrationStyles} />
    </div>
  )
}
