import React from 'react'
import { css } from '@emotion/core'
import { TABLET_OR_MORE, DESKTOP_OR_MORE, DESKTOP_WIDE } from '~/constants/theme.styles'

const headingStyles = css`
  font-size: 32px;
  font-weight: 600;
  width: 100%;
  @media ${TABLET_OR_MORE} {
    font-size: 38px;
  }
  @media ${DESKTOP_OR_MORE} {
    font-size: 40px;
  }
  @media ${DESKTOP_WIDE} {
    font-size: 60px;
  }
`
export const SectionHeading: React.FC = ({ children, ...rest }) => {
  return (
    <h2 css={headingStyles} {...rest}>
      {children}
    </h2>
  )
}
