import React from 'react'
import { css } from '@emotion/core'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { TABLET_OR_LESS, TABLET_OR_MORE, DESKTOP_OR_MORE } from '~/constants/theme.styles'
import Link from 'next/link'
import Grid from '../../common/Grid'
import PostPreview from '../blog/PostPreview'
import { constructArticleUri, getCategoryRoute } from '../blog/blogUtils'
import { TextBreak } from './TextBreak'
import { SectionHeading } from './SectionHeading'
import { HighlightText } from './HighlightText'
import Container from '~/components/common/Container'
import { AnchorButton } from '~/components/common/Button/Button'

const GET_RELATION_JOBS = gql`
  query entries($limit: Int!, $order: String, $featured: FeaturedEnum) {
    entries(section: [blog], limit: $limit, order: $order, featured: $featured) {
      __typename
      ... on Blog {
        uri
        title
        description
        heroImage {
          url
        }
        categories {
          title
          uri
        }
      }
    }
  }
`

const FeaturedArticles = () => {
  return (
    <Query
      query={GET_RELATION_JOBS}
      variables={{
        limit: 3,
        featured: 'yes',
        order: 'dateCreated desc'
      }}
    >
      {(query: any) => {
        if (query.data && query.data.entries && query.data.entries.length) {
          const [first = null, second = null, third = null] = query.data.entries

          return (
            <div
              css={css`
                margin-top: 60px;
                @media ${TABLET_OR_MORE} {
                  margin-top: 75px;
                }
                @media ${DESKTOP_OR_MORE} {
                  margin-top: 95px;
                }
              `}
            >
              <div
                css={css`
                  width: 100%;
                  max-width: 100%;
                `}
              >
                <Grid xsGap={60} mdGap={100} lgGap={70}>
                  {first ? (
                    <Grid.Col xs={12} md={6} lg={4}>
                      <PostPreview
                        uri={constructArticleUri(first)}
                        imageUrl={first.heroImage.length > 0 ? first.heroImage[0].url : null}
                        title={first.title}
                        description={first.description}
                      />
                    </Grid.Col>
                  ) : null}
                  {second ? (
                    <Grid.Col xs={12} md={6} lg={4}>
                      <PostPreview
                        uri={constructArticleUri(second)}
                        imageUrl={second.heroImage.length > 0 ? second.heroImage[0].url : null}
                        title={second.title}
                        description={second.description}
                      />
                    </Grid.Col>
                  ) : null}
                  {third ? (
                    <Grid.Col
                      xs={12}
                      md={6}
                      lg={4}
                      css={css`
                        @media ${TABLET_OR_LESS} {
                          display: none;
                        }
                      `}
                    >
                      <PostPreview
                        uri={constructArticleUri(third)}
                        imageUrl={third.heroImage.length > 0 ? third.heroImage[0].url : null}
                        title={third.title}
                        description={third.description}
                      />
                    </Grid.Col>
                  ) : null}
                </Grid>
              </div>
            </div>
          )
        } else {
          return null
        }
      }}
    </Query>
  )
}

const blogSection = css`
  background: #f6f7fa;
  padding-top: 65px;
  padding-bottom: 100px;
  @media ${TABLET_OR_MORE} {
    padding-top: 100px;
  }
`

export const BlogSection = () => {
  return (
    <div css={blogSection}>
      <Container>
        <SectionHeading
          css={css`
            text-align: center;
          `}
        >
          Learn more on
          <TextBreak>
            the <HighlightText>Joblist Blog</HighlightText>
          </TextBreak>
        </SectionHeading>
        <FeaturedArticles />
        <div
          css={css`
            text-align: center;
          `}
        >
          <Link
            prefetch={false}
            href={{
              pathname: '/category',
              query: { categorySlug: 'all' }
            }}
            as={getCategoryRoute('all')}
            passHref
          >
            <AnchorButton
              oversize
              element="a"
              href=""
              arrow
              extraCss={css`
                margin-top: 50px;
                width: 265px;
                @media ${TABLET_OR_MORE} {
                  margin-top: 60px;
                }
              `}
            >
              View All Articles
            </AnchorButton>
          </Link>
        </div>
      </Container>
    </div>
  )
}
