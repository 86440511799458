import React from 'react'
import { css } from '@emotion/core'
import { PURPLE, BLUE, BLACK } from '../../../constants/theme.styles'

export type LinkColors = 'purple' | 'blue' | 'black'
export type UnderlineOptions = 'none' | 'always' | 'notOnHover' | 'onHoverOnly'

const styledLinkStyles = css`
  cursor: pointer;
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`

const colorStyles = (color: LinkColors) => {
  if (color === 'black') {
    return css`
      color: ${BLACK};
      :hover {
        color: ${BLUE};
      }
    `
  }
  if (color === 'purple') {
    return css`
      color: ${PURPLE};
      :hover {
        color: #623dc7;
      }
    `
  }
  if (color === 'blue') {
    return css`
      color: ${BLUE};
      :hover {
        color: #15a1c3;
      }
    `
  }
}

const underlineStyles = (underline: UnderlineOptions) => {
  if (underline === 'none') {
    return css`
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
    `
  }
  if (underline === 'always') {
    return css`
      text-decoration: underline;
      :hover {
        text-decoration: underline;
      }
    `
  }
  if (underline === 'notOnHover') {
    return css`
      text-decoration: underline;
      :hover {
        text-decoration: none;
      }
    `
  }
  if (underline === 'onHoverOnly') {
    return css`
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    `
  }
}

const boldStyles = css`
  font-weight: 700;
`

export type StyledLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  /** Use for a links that open a different site */
  external?: boolean
  /** Makes the link color the same as the current text color and adds an underline */
  color?: LinkColors
  bold?: boolean
  underline?: UnderlineOptions
  children: React.ReactNode
}

const StyledLink = React.forwardRef(
  (
    { color = 'purple', bold, underline, external, children, ...rest }: StyledLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const target = external ? '_blank' : undefined
    const rel = external ? 'noopener noreferrer' : undefined
    return (
      <a
        ref={ref}
        css={[
          styledLinkStyles,
          colorStyles(color),
          bold ? boldStyles : null,
          underline ? underlineStyles(underline) : null
        ]}
        target={target}
        rel={rel}
        {...rest}
      >
        {children}
      </a>
    )
  }
)

export default StyledLink
