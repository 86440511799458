import React from 'react'
import { css } from '@emotion/core'
import { TABLET_OR_MORE, DESKTOP_OR_MORE } from '~/constants/theme.styles'

const textStyles = css`
  font-size: 18px;
  line-height: 1.6em;
  color: #222222;
  width: 100%;
  @media ${TABLET_OR_MORE} {
    font-size: 18px;
    line-height: 31px;
  }
  @media ${DESKTOP_OR_MORE} {
    font-size: 22px;
    line-height: 34px;
  }
`

export const ContentText: React.FC = ({ children, ...rest }) => {
  return (
    <p css={textStyles} {...rest}>
      {children}
    </p>
  )
}
