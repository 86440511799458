import React, { useEffect } from 'react'
import { HeroSection } from './HeroSection'
import { DescriptionSection } from './DescriptionSection'
import { PersonaSection } from './PersonaSection'
import { PhoneSliderSection } from './PhoneSliderSection'
import { BenefitsSections } from './BenefitsSections'
import { BlogSection } from './BlogSection'
import { SiteNav } from '../../app/Header/SiteNav'
import { Mixpanel } from '~/lib/mixpanel'

const NewHomePage = ({ hasSuperPostFeature }: { hasSuperPostFeature: boolean }) => {
  useEffect(() => {
    Mixpanel.track('Homepage', {})
  }, [])
  return (
    <div>
      <SiteNav hasSuperPostFeature={hasSuperPostFeature} />
      <HeroSection />
      <DescriptionSection />
      <PersonaSection />
      <PhoneSliderSection />
      <BenefitsSections />
      <BlogSection />
    </div>
  )
}

export default NewHomePage
