import React from 'react'

import { UserDevice, useUserDevice } from '../../context/UserDeviceContext'

export interface UserDeviceProps {
  userDevice: UserDevice
}

function withUserDevice<P extends UserDeviceProps>(Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof UserDeviceProps>) => {
    const userDevice = useUserDevice()
    return <Component {...(props as P)} userDevice={userDevice} />
  }
}

export default withUserDevice
