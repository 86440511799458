import React, { createContext } from 'react'
import { withRouter, SingletonRouter, useRouter } from 'next/router'
import { useEffect } from 'react'
import NProgress from 'nprogress'

export type State = {
  [name: string]: boolean
}

export type Actions = {
  setOpen: (name: string, open: boolean) => void
  close: () => void
}

interface ModalsProviderProps {
  state?: State
  router: SingletonRouter
}

const ModalStateContext = createContext<State | undefined>(undefined)
const ModalActionsContext = createContext<Actions | undefined>(undefined)

function modalReducer(state: State, action: any): State {
  // console.log('Modal reducer', action, state)
  return {
    ...state,
    [action.name]: action.open
  }
}

let currentlyShowingQuiz = false

const ModalProviderComponent: React.FC<ModalsProviderProps> = ({
  state: initialState,
  children
}) => {
  const [state, dispatch] = React.useReducer(modalReducer, {
    search: false,
    onboarding: false,
    mobileSearchFilters: false,
    syndication: false,
    ...initialState
  })

  const router = useRouter()

  const setOpen = (name: string, open: boolean) => {
    if (name === 'onboarding' && open === true) {
      window.history.pushState(
        { url: '/quiz', as: '/quiz', options: {}, __N: true },
        'Quiz',
        '/quiz'
      )
      currentlyShowingQuiz = true
    }

    dispatch({ name, open })
  }

  const close = () => {
    for (const name in state) {
      dispatch({ name, open: false })
    }
  }

  const actions = {
    setOpen,
    close
  }

  useEffect(() => {
    currentlyShowingQuiz = window.location.pathname.indexOf('/quiz') !== -1

    const beforeHistoryChange = (as: string) => {
      const isGoingToQuiz = as === '/quiz'
      const isRouterSame = router.asPath === as

      // console.log('beforeHistoryChange', as)
      if (currentlyShowingQuiz && !isGoingToQuiz) {
        if (as.indexOf('/search') !== -1) {
          window.history.pushState(
            {
              url: '/quiz',
              as: '/quiz',
              options: {},
              __N: true
            },
            '',
            '/quiz'
          )
        } else if (isRouterSame) {
          currentlyShowingQuiz = false

          //console.log('cancelling')
          window.history.pushState(
            {
              url: as,
              as,
              options: {},
              __N: true
            },
            '',
            as
          )

          NProgress.done()

          const err = new Error()
          // @ts-ignore
          err.cancelled = true
          throw err
        }
      } else {
        currentlyShowingQuiz = isGoingToQuiz
      }
    }

    const onBeforeNextPopState = function({ as }: any) {
      // console.log('beforePopState', as, currentlyShowingQuiz, state)

      const isGoingToQuiz = as === '/quiz'
      const isRouterSame = router.asPath === as

      // Make sure all modals are closed before continue, just incase
      close()

      if (isGoingToQuiz) {
        currentlyShowingQuiz = true

        // Make sure we show quiz because we're going there
        dispatch({
          name: 'onboarding',
          open: true
        })

        return false
      } else if (currentlyShowingQuiz) {
        currentlyShowingQuiz = false

        // Double make sure quiz is closed because we're leaving
        dispatch({
          name: 'onboarding',
          open: false
        })

        if (isRouterSame) {
          return false
        }
      }

      return true
    }

    const onBeforeNativePopState = function(event: any) {
      // May not be needed but lets do it anyway
      if (event.currentTarget?.location.pathname.indexOf('/quiz') === -1) {
        dispatch({
          name: 'onboarding',
          open: false
        })
      }
    }

    router.beforePopState(onBeforeNextPopState)
    router.events.on('beforeHistoryChange', beforeHistoryChange)
    window.addEventListener('popstate', onBeforeNativePopState)

    return () => {
      router.beforePopState(function() {
        return true
      })
      router.events.off('beforeHistoryChange', beforeHistoryChange)
      window.removeEventListener('popstate', onBeforeNativePopState)
    }
  }, [])

  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionsContext.Provider value={actions}>{children}</ModalActionsContext.Provider>
    </ModalStateContext.Provider>
  )
}

function useModalState() {
  const state = React.useContext(ModalStateContext)

  if (state === undefined) {
    throw new Error('useModalState must be used within a ModalProvider')
  }

  return state
}

function useModalActions() {
  const actions = React.useContext(ModalActionsContext)

  if (actions === undefined) {
    throw new Error('useModalActions must be used within a ModalProvider')
  }

  return actions
}

function useModal(): [State, Actions] {
  return [useModalState(), useModalActions()]
}

interface ConsumerProps {
  children: (state: State | undefined, actions: Actions | undefined) => React.ReactNode
}
const ModalConsumer: React.FC<ConsumerProps> = ({ children }) => {
  return (
    <ModalStateContext.Consumer>
      {state => {
        if (state === undefined) {
          throw new Error('ModalConsumer must be used within a ModalProvider')
        }
        return (
          <ModalActionsContext.Consumer>
            {actions => {
              if (actions === undefined) {
                throw new Error('ModalConsumer must be used within a ModalProvider')
              }
              return children(state, actions)
            }}
          </ModalActionsContext.Consumer>
        )
      }}
    </ModalStateContext.Consumer>
  )
}

const ModalProvider = withRouter(ModalProviderComponent)

export { ModalProvider, ModalConsumer, useModal, useModalState, useModalActions }
