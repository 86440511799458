import React, { useEffect } from 'react'
import Meta from '../components/app/Meta'
import Footer from '../components/app/Footer'
import NewHome from '../components/domain/home/NewHome'
import PrivacyNotification from '../components/common/PrivacyNotification'
import { NextPage } from 'next'
import Cookie from 'js-cookie'
import withAppProviders from '~/components/hoc/withAppProviders'
import { NextPageContextWithApollo, UserParams } from '~/types/types'
import { ModalProvider } from '../context/ModalContext'
import DynamicOnboardingModal from '~/components/modals/DynamicOnboardingModal'

import {
  resolveSearchParams,
  extractLocaleFromPath,
  setProvidersFromFlags
} from '~/lib/utils/search'
import { IJobSearchParams, SearchParamsProvider } from '~/context/SearchParamsContext'
import { setABTestOverwrites } from '~/lib/ab-testing'
import { getUserParams, parseCookies } from '~/lib/utils/cookies'
import { css } from '@emotion/core'
import { Country } from '~/constants/app-constants'
import { useMeQuery } from '~/generated/graphql'
import { TopAdminBar } from '~/components/domain/admin/TopAdminBar'
interface Props {
  searchParams: IJobSearchParams
  userParams: UserParams
  abTestOverwrites: { [key: string]: string }
}

const MainFooter = ({ hasSuperPostFeature }: { hasSuperPostFeature: boolean }) => {
  return <Footer hasSuperPostFeature={hasSuperPostFeature} showJobPostingStarted />
}

const IndexPage: NextPage<Props> = ({ searchParams, userParams, abTestOverwrites }) => {
  const { data: meData } = useMeQuery({ fetchPolicy: 'cache-only' })
  const isLoggedIn = meData?.me

  const hasSuperPostFeature = !isLoggedIn

  useEffect(() => {
    Cookie.set('homepageLanded', 'true')
  }, [])

  return (
    <div
      css={css`
        width: 100%;
        background: #ffffff;
      `}
    >
      <SearchParamsProvider value={searchParams}>
        <TopAdminBar />
        <Meta
          title="Find Jobs Anywhere with the Best Job Search Site | Joblist"
          description="Your job search is about to end. Joblist is the best place to find a job, aggregating, and personalizing every listing near you so your dream job becomes a reality."
        />
        <ModalProvider>
          <NewHome hasSuperPostFeature={hasSuperPostFeature} />
          <DynamicOnboardingModal />
          <MainFooter hasSuperPostFeature={hasSuperPostFeature} />
        </ModalProvider>
        <PrivacyNotification />
      </SearchParamsProvider>
    </div>
  )
}

IndexPage.getInitialProps = async (context: NextPageContextWithApollo) => {
  const req = context && context.req
  const cookies = parseCookies(req)
  // setup searchParams
  const searchParams = resolveSearchParams(context.query, context.flags, cookies)

  searchParams.country = extractLocaleFromPath(req, context)
  searchParams.userCountry = cookies.userCountry || Country.US
  searchParams.searchCountry =
    searchParams.searchCountry || cookies.userLocationCountry || searchParams.country
  // set providers which are passed in from flags and are per conuntry
  setProvidersFromFlags(searchParams, context.flags)

  const userParams = getUserParams(context.req)
  const abTestOverwrites = setABTestOverwrites(context.query)

  return {
    searchParams,
    abTestOverwrites,
    userParams
  }
}

export default withAppProviders(IndexPage)
