import React from 'react'
import { css } from '@emotion/core'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { TABLET_OR_MORE, DESKTOP_OR_MORE, DESKTOP_WIDE } from '~/constants/theme.styles'

import Container from '~/components/common/Container'
import { TextBreak } from './TextBreak'

const mainContainerStyles = css`
  display: flex;
  padding-top: 100px;
  @media ${DESKTOP_WIDE} {
    padding-top: 125px;
  }
`
const rowStyles = css`
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;

  @media ${DESKTOP_OR_MORE} {
    margin-left: -30px;
  }
  @media ${DESKTOP_WIDE} {
    margin-left: -50px;
  }
`

const columnStyles = css`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: #f6f7fa;
  border-radius: 15px;
  width: calc(50% - 15px);
  margin-left: 15px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
  @media (min-width: 375px) {
    padding: 20px;
  }
  @media ${TABLET_OR_MORE} {
    border-radius: 30px;
    width: calc(25% - 15px);
    margin-bottom: 0;
    padding: 3%;
  }
  @media ${DESKTOP_OR_MORE} {
    margin-left: 30px;
    width: calc(25% - 30px);
  }
  @media ${DESKTOP_WIDE} {
    margin-left: 50px;
    width: calc(25% - 50px);
  }
`

const imgContainerStyles = css`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const imgStyles = css`
  width: 60%;
  max-width: 100px;
  @media ${TABLET_OR_MORE} {
    max-width: 105px;
  }
`

const textContainerStyles = css`
  margin-top: 15px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${TABLET_OR_MORE} {
    font-size: 16px;
  }
  @media ${DESKTOP_OR_MORE} {
    font-size: 18px;
    margin-top: 20px;
  }
  @media ${DESKTOP_WIDE} {
    height: 48px;
    margin-top: 25px;
    font-size: 20px;
  }
`

export const PersonaSection = () => {
  return (
    <div css={mainContainerStyles}>
      <Container>
        <div css={rowStyles}>
          <div css={columnStyles}>
            <div css={imgContainerStyles}>
              <img css={imgStyles} src={`${PUBLIC_ASSET_PREFIX}/static/images/covid.svg`} />
            </div>
            <div css={textContainerStyles}>
              <span>
                Unemployed due <TextBreak>to COVID-19</TextBreak>
              </span>
            </div>
          </div>
          <div css={columnStyles}>
            <div css={imgContainerStyles}>
              <img css={imgStyles} src={`${PUBLIC_ASSET_PREFIX}/static/images/new_grad.svg`} />
            </div>
            <div css={textContainerStyles}>Recent Grad</div>
          </div>
          <div css={columnStyles}>
            <div css={imgContainerStyles}>
              <img css={imgStyles} src={`${PUBLIC_ASSET_PREFIX}/static/images/remote.svg`} />
            </div>
            <div css={textContainerStyles}>Open to Remote</div>
          </div>
          <div css={columnStyles}>
            <div css={imgContainerStyles}>
              <img css={imgStyles} src={`${PUBLIC_ASSET_PREFIX}/static/images/puzzle.svg`} />
            </div>
            <div css={textContainerStyles}>Something Else</div>
          </div>
        </div>
      </Container>
    </div>
  )
}
