import React, { useState } from 'react'
import { css } from '@emotion/core'
import { TextBreak } from './TextBreak'
import { HighlightText } from './HighlightText'
import Carousel from 'nuka-carousel'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import {
  TABLET_OR_MORE,
  DESKTOP_OR_MORE,
  DESKTOP_WIDE,
  YELLOW,
  BLUE,
  PURPLE
} from '~/constants/theme.styles'

import Container from '~/components/common/Container'
import { SectionHeading } from './SectionHeading'

const desktopWrapper = css`
  position: relative;
  margin-top: 60px;
  display: none;
  @media ${TABLET_OR_MORE} {
    display: block;
  }
  @media ${DESKTOP_WIDE} {
    margin-top: 80px;
  }
`
const mobileWrapper = css`
  position: relative;
  margin-top: 60px;
  @media ${TABLET_OR_MORE} {
    display: none;
  }
`

const phoneSliderSection = css`
  overflow: hidden;
  margin-top: 90px;
  @media ${DESKTOP_OR_MORE} {
    margin-top: 105px;
  }
  @media ${DESKTOP_WIDE} {
    margin-top: 125px;
  }
  .slider-frame {
    overflow: visible !important;
    max-width: 100% !important;
  }
`
const outlineImageMobile = css`
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @media (min-width: 375px) {
    width: 350px;
  }
  @media (min-width: 414px) {
    width: 385px;
  }
`

const outlineImageDesktop = css`
  width: 48%;
  @media ${DESKTOP_OR_MORE} {
    width: 46%;
  }
`
const logoStyles = css`
  display: none;
  position: absolute;
  width: 15%;
  z-index: 5;
  left: 10%;
  top: 11%;
  @media ${DESKTOP_OR_MORE} {
    display: block;
  }
`

const buttonCircle = css`
  border-radius: 50%;
  padding: 0;
  width: 22px;
  height: 22px;
  margin-left: 5px;
  margin-right: 5px;
  border: 3px solid #000000;
  background: #ffffff;
`
const butttonCircleActive = css`
  background: ${YELLOW};
`

// INNNER SLIDE CONTENTS

const spacedWrapper = css`
  width: 220px;
  position: absolute;
  top: 45px;
  left: calc(50% + 33px);
  transform: translateX(-50%);
  @media (min-width: 375px) {
    width: 250px;
  }
  @media (min-width: 414px) {
    top: 50px;
    width: 290px;
  }
`

const phoneSlideContainer = css`
  text-align: center;
  padding: 15px;
  border-radius: 20px;
  width: 190px;
  background: #fff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.12);
  @media (min-width: 375px) {
    width: 220px;
  }
  @media (min-width: 414px) {
    padding: 20px;
    width: 260px;
  }
  @media ${TABLET_OR_MORE} {
    padding: 17px;
    width: 28%;
    margin-left: 14px;
    max-width: 235px;
  }

  @media ${DESKTOP_OR_MORE} {
    width: 24%;
    margin-left: 30px;
    padding: 20px;
    max-width: none;
  }
  @media (min-width: 1120px) {
    padding: 25px;
  }
  @media ${DESKTOP_WIDE} {
    padding: 35px;
  }
`

const slideImg = css`
  width: 83%;
  @media (min-width: 375px) {
    width: 100%;
  }
`

const slideStep = css`
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 10px;
  font-weight: 900;
  @media (min-width: 375px) {
    font-size: 12px;
  }
  @media (min-width: 414px) {
    font-size: 14px;
  }
`

const slideStep1 = css`
  color: ${BLUE};
`
const slideStep2 = css`
  color: ${YELLOW};
`
const slideStep3 = css`
  color: ${PURPLE};
`

const slideHeading = css`
  font-size: 12px;
  margin-top: 10px;
  @media (min-width: 375px) {
    font-size: 14px;
  }
  @media (min-width: 414px) {
    font-size: 16px;
  }
  @media (min-width: 864px) {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 17px;
  }
  @media (min-width: 1066px) {
    margin-top: 16px;
    margin-bottom: 26px;
  }
`
const slideText = css`
  font-size: 10px;
  margin-top: 10px;
  @media (min-width: 375px) {
    font-size: 12px;
  }
  @media (min-width: 414px) {
    font-size: 14px;
  }
  @media ${DESKTOP_WIDE} {
    font-size: 16px;
  }
`

export const PhoneSliderSection = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  return (
    <div css={phoneSliderSection}>
      <Container>
        <SectionHeading
          css={css`
            text-align: center;
          `}
        >
          Start building{' '}
          <TextBreak>
            your <HighlightText color="yellow">Joblist today</HighlightText>
          </TextBreak>
        </SectionHeading>

        <div css={mobileWrapper}>
          <img
            src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-outline@2x.png`}
            css={outlineImageMobile}
          />
          <div
            css={css`
              text-align: center;
              margin-top: 21px;
            `}
          >
            <button
              onClick={() => {
                setSlideIndex(0)
              }}
              css={[buttonCircle, slideIndex === 0 ? butttonCircleActive : null]}
            >
              &nbsp;
            </button>
            <button
              onClick={() => {
                setSlideIndex(1)
              }}
              css={[buttonCircle, slideIndex === 1 ? butttonCircleActive : null]}
            >
              &nbsp;
            </button>
            <button
              onClick={() => {
                setSlideIndex(2)
              }}
              css={[buttonCircle, slideIndex === 2 ? butttonCircleActive : null]}
            >
              &nbsp;
            </button>
          </div>
          <div css={spacedWrapper}>
            <Carousel
              withoutControls
              slideIndex={slideIndex}
              afterSlide={slideIndex => setSlideIndex(slideIndex)}
              edgeEasing="easePolyInOut"
            >
              <div css={phoneSlideContainer}>
                <img
                  css={slideImg}
                  src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-slide-question@2x.png`}
                />
                <div css={[slideStep, slideStep1]}>Step 1</div>
                <h3 css={slideHeading}>Answer Questions</h3>
                <p css={slideText}>
                  Answer a few questions about your situation and job preferences.
                </p>
              </div>
              <div css={phoneSlideContainer}>
                <img
                  css={slideImg}
                  src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-slide-matches@2x.png`}
                />
                <div css={[slideStep, slideStep2]}>Step 2</div>
                <h3 css={slideHeading}>Get Matches</h3>
                <p css={slideText}>
                  Browse personalized results based on our analysis of millions of jobs.
                </p>
              </div>
              <div css={phoneSlideContainer}>
                <img
                  css={slideImg}
                  src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-slide-favorites@2x.png`}
                />
                <div css={[slideStep, slideStep3]}>Step 3</div>
                <h3 css={slideHeading}>Save Favorites</h3>
                <p css={slideText}>
                  Save jobs to your personalized list and apply when you’re ready.
                </p>
              </div>
            </Carousel>
          </div>
        </div>

        <div css={desktopWrapper}>
          <img
            src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-outline@2x.png`}
            css={outlineImageDesktop}
          />
          <img
            src={`${PUBLIC_ASSET_PREFIX}/static/icons/Joblist_Logo_Black.svg`}
            css={logoStyles}
          />
          <div
            css={css`
              position: absolute;
              top: 12%;
              right: 0;
              left: 0;
              display: flex;
              justify-content: flex-end;
              @media ${DESKTOP_OR_MORE} {
                top: 21%;
              }
            `}
          >
            <div css={phoneSlideContainer}>
              <img
                css={slideImg}
                src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-slide-question@2x.png`}
              />
              <div css={[slideStep, slideStep1]}>Step 1</div>
              <h3 css={slideHeading}>Answer Questions</h3>
              <p css={slideText}>
                Answer a few questions about your situation and job preferences.
              </p>
            </div>
            <div css={phoneSlideContainer}>
              <img
                css={slideImg}
                src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-slide-matches@2x.png`}
              />
              <div css={[slideStep, slideStep2]}>Step 2</div>
              <h3 css={slideHeading}>Get Matches</h3>
              <p css={slideText}>
                Browse personalized results based on our analysis of millions of jobs.
              </p>
            </div>
            <div css={phoneSlideContainer}>
              <img
                css={slideImg}
                src={`${PUBLIC_ASSET_PREFIX}/static/images/phone-slide-favorites@2x.png`}
              />
              <div css={[slideStep, slideStep3]}>Step 3</div>
              <h3 css={slideHeading}>Save Favorites</h3>
              <p css={slideText}>
                Save jobs to your personalized list and apply when you’re ready.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
