import React from 'react'
import { css } from '@emotion/core'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX
import { TABLET_OR_MORE, DESKTOP_OR_MORE, DESKTOP_WIDE } from '~/constants/theme.styles'

import ArrowLink from '~/components/common/Links/ArrowLink'
import Container from '~/components/common/Container'
import { ContentText } from './ContentText'
import { useModalActions } from '~/context/ModalContext'

const benefitsRow = css`
  display: flex;
  flex-direction: column;
  max-width: 385px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  @media ${TABLET_OR_MORE} {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    flex-direction: row;
  }
`

const benefitsRowReverse = css`
  @media ${TABLET_OR_MORE} {
    flex-direction: row-reverse;
  }
`

const benefitsRowContent = css`
  width: 100%;
  text-align: center;
  @media ${TABLET_OR_MORE} {
    text-align: left;
    width: 55%;
    padding: 7% 40px 0 40px;
  }
  @media ${DESKTOP_OR_MORE} {
    width: 50%;
    padding: 7% 20px 0 40px;
  }
  @media ${DESKTOP_WIDE} {
    padding: 7% 90px 0 90px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    @media ${TABLET_OR_MORE} {
      font-size: 34px;
    }
    @media ${DESKTOP_WIDE} {
      font-size: 46px;
    }
  }
  p {
    margin-top: 30px;
    @media ${DESKTOP_OR_MORE} {
      margin-top: 45px;
    }
  }
`

const benefitsRowImage = css`
  margin-top: 40px;
  text-align: center;
  @media ${TABLET_OR_MORE} {
    margin-top: 0;
    width: 45%;
  }
  @media ${DESKTOP_OR_MORE} {
    width: 50%;
  }
  img {
    @media ${TABLET_OR_MORE} {
      width: 90%;
      max-width: 380px;
    }
    @media ${DESKTOP_WIDE} {
      width: 65%;
      max-width: none;
    }
  }
`

export const BenefitsSections = () => {
  const modalActions = useModalActions()
  return (
    <Container
      css={css`
        padding-top: 90px;
        padding-bottom: 15px;
        @media ${TABLET_OR_MORE} {
          padding-bottom: 90px;
        }
        @media ${DESKTOP_WIDE} {
          padding-top: 125px;
          padding-bottom: 125px;
        }
      `}
    >
      <div
        css={[
          benefitsRow,
          css`
            margin-top: 0;
          `
        ]}
      >
        <div css={benefitsRowContent}>
          <h2>Personal</h2>
          <ContentText>
            This is your list, customized and curated by you. We generate the top matches based on
            your specific criteria and then you make the list your own.
          </ContentText>
        </div>
        <div css={benefitsRowImage}>
          <img src={`${PUBLIC_ASSET_PREFIX}/static/images/benefit-personal@2x.png`} />
        </div>
      </div>
      <div css={[benefitsRow, benefitsRowReverse]}>
        <div css={benefitsRowContent}>
          <h2>Flexible</h2>
          <ContentText>
            Save jobs now, apply later. No more browsing on your phone and finding the right job,
            then having to track it down later.
          </ContentText>
        </div>
        <div css={benefitsRowImage}>
          <img src={`${PUBLIC_ASSET_PREFIX}/static/images/benefit-flexible@2x.png`} />
        </div>
      </div>
      <div css={benefitsRow}>
        <div css={benefitsRowContent}>
          <h2>Shareable</h2>
          <ContentText>
            Access your joblist from anywhere and share it (if you want to). We can give you a
            public URL that you can share directly with friends and family.
          </ContentText>
          <ArrowLink
            bold
            underline="always"
            color="black"
            css={css`
              margin-top: 46px;
              margin-left: auto;
              margin-right: auto;
              font-size: 18px;
              display: inline-block;
              @media ${TABLET_OR_MORE} {
                font-size: 18px;
                line-height: 31px;
                margin-left: 0;
                margin-right: 0;
                margin-top: 30px;
              }
              @media ${DESKTOP_OR_MORE} {
                font-size: 22px;
                line-height: 34px;
              }
            `}
            onClick={() => {
              modalActions.setOpen('onboarding', true)
            }}
          >
            Ready to build your Joblist?
          </ArrowLink>
        </div>
        <div css={benefitsRowImage}>
          <img src={`${PUBLIC_ASSET_PREFIX}/static/images/benefit-shareable@2x.png`} />
        </div>
      </div>
    </Container>
  )
}
